import React from "react";
import { concatClassNames as cn } from "@sys42/utils";

import { AutoLink } from "../helpers/AutoLink";
import { Image } from "../Image";

import * as styles from "./styles.module.css";

export function BlogTeaser(props) {
  const { title, linkHref, linkTo, image } = props;

  return (
    <AutoLink className={cn(styles.blogTeaser)} href={linkHref} to={linkTo}>
      {image && <Image image={image} className={styles.image} />}
      <div className={styles.title}>{title}</div>
    </AutoLink>
  );
}

BlogTeaser.Group = function BlogTeaserGroup(props) {
  const { className, ...restProps } = props;
  return <div className={cn(styles.group, className)} {...restProps} />;
};
